import { useEffect, useState } from 'react';

import {Slider, InputNumber, Select, Checkbox, Collapse, Button, Tooltip } from 'antd';
import {
    CgArrowDownR,
    CgArrowRightR,
    CgArrowUpR,
    CgArrowLeftR,
    CgAlignLeft,
} from 'react-icons/cg';
import { MdOutlineWrapText, MdOutlineVerticalDistribute, MdRestartAlt } from 'react-icons/md';

const { Option } = Select;


const units = ['px', '%', 'em', 'rem'];
const flexDirections = [{ 'row': <CgArrowDownR size="medium" /> }, { 'row-reverse': <CgArrowUpR size="medium" /> }, { 'column': <CgArrowRightR size="medium" /> }, { 'column-reverse': <CgArrowLeftR size="medium" /> }];
const flexWrap = [{ 'wrap': <MdOutlineWrapText size="medium" style={{ transform: 'scale(-1, 1)' }} /> }, { 'nowrap': <MdOutlineVerticalDistribute size="medium" /> }, { 'wrap-reverse': <MdOutlineWrapText size="medium" /> }];

const justifyContents = [{
    'flex-start': <> <svg stroke="currentColor" fill="none" viewBox="0 0 5 9" stroke-width="0" height="medium" width="medium" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.349609 7.87539C0.349609 7.93506 0.373315 7.99229 0.41551 8.03449C0.457706 8.07668 0.514936 8.10039 0.574609 8.10039C0.634283 8.10039 0.691513 8.07668 0.733708 8.03449C0.775904 7.99229 0.799609 7.93506 0.799609 7.87539V1.12539C0.799609 1.06572 0.775904 1.00849 0.733708 0.966292C0.691513 0.924096 0.634283 0.900391 0.574609 0.900391C0.514936 0.900391 0.457706 0.924096 0.41551 0.966292C0.373315 1.00849 0.349609 1.06572 0.349609 1.12539V7.87539Z" fill="currentColor" />
        <path d="M2.59922 1.80078C2.36052 1.80078 2.13161 1.8956 1.96282 2.06439C1.79404 2.23317 1.69922 2.46209 1.69922 2.70078V6.30078C1.69922 6.53948 1.79404 6.76839 1.96282 6.93718C2.13161 7.10596 2.36052 7.20078 2.59922 7.20078H3.49922C3.73791 7.20078 3.96683 7.10596 4.13561 6.93718C4.3044 6.76839 4.39922 6.53948 4.39922 6.30078V2.70078C4.39922 2.46209 4.3044 2.23317 4.13561 2.06439C3.96683 1.8956 3.73791 1.80078 3.49922 1.80078H2.59922Z" fill="currentColor" />
    </svg>
    </>
},
{
    'center': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.57461 8.10039C1.51494 8.10039 1.45771 8.07668 1.41551 8.03449C1.37331 7.99229 1.34961 7.93506 1.34961 7.87539V1.12539C1.34961 1.06572 1.37331 1.00849 1.41551 0.966292C1.45771 0.924096 1.51494 0.900391 1.57461 0.900391C1.63428 0.900391 1.69151 0.924096 1.73371 0.966292C1.7759 1.00849 1.79961 1.06572 1.79961 1.12539V7.87539C1.79961 7.93506 1.7759 7.99229 1.73371 8.03449C1.69151 8.07668 1.63428 8.10039 1.57461 8.10039Z" fill="currentColor" />
        <path d="M7.19922 7.87539C7.19922 7.93506 7.22292 7.99229 7.26512 8.03449C7.30732 8.07668 7.36454 8.10039 7.42422 8.10039C7.48389 8.10039 7.54112 8.07668 7.58332 8.03449C7.62551 7.99229 7.64922 7.93506 7.64922 7.87539V1.12539C7.64922 1.06572 7.62551 1.00849 7.58332 0.966292C7.54112 0.924096 7.48389 0.900391 7.42422 0.900391C7.36454 0.900391 7.30732 0.924096 7.26512 0.966292C7.22292 1.00849 7.19922 1.06572 7.19922 1.12539V7.87539Z" fill="currentColor" />
        <path d="M4.04941 1.80078C3.81072 1.80078 3.5818 1.8956 3.41302 2.06439C3.24424 2.23317 3.14941 2.46209 3.14941 2.70078V6.30078C3.14941 6.53948 3.24424 6.76839 3.41302 6.93718C3.5818 7.10596 3.81072 7.20078 4.04941 7.20078H4.94941C5.18811 7.20078 5.41703 7.10596 5.58581 6.93718C5.75459 6.76839 5.84941 6.53948 5.84941 6.30078V2.70078C5.84941 2.46209 5.75459 2.23317 5.58581 2.06439C5.41703 1.8956 5.18811 1.80078 4.94941 1.80078H4.04941Z" fill="currentColor" />
    </svg>
    </>
},
{
    'flex-end': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.0498 0.225196C4.0498 0.165522 4.0261 0.108293 3.9839 0.0660968C3.94171 0.023901 3.88448 0.000195995 3.8248 0.000196C3.76513 0.000196005 3.7079 0.023901 3.66571 0.0660968C3.62351 0.108293 3.5998 0.165522 3.5998 0.225196L3.5998 6.9752C3.5998 7.03487 3.62351 7.0921 3.66571 7.13429C3.7079 7.17649 3.76513 7.2002 3.8248 7.2002C3.88448 7.2002 3.94171 7.17649 3.9839 7.13429C4.0261 7.0921 4.0498 7.03487 4.0498 6.9752L4.0498 0.225196Z" fill="currentColor" />
        <path d="M1.8002 6.2998C2.03889 6.2998 2.26781 6.20498 2.43659 6.0362C2.60537 5.86742 2.7002 5.6385 2.7002 5.3998L2.70019 1.7998C2.70019 1.56111 2.60537 1.33219 2.43659 1.16341C2.26781 0.994626 2.03889 0.899805 1.80019 0.899805L0.900195 0.899805C0.6615 0.899805 0.432582 0.994626 0.263799 1.16341C0.095016 1.33219 0.000194851 1.56111 0.000194871 1.7998L0.000195186 5.3998C0.000195207 5.6385 0.0950164 5.86742 0.263799 6.0362C0.432582 6.20498 0.6615 6.2998 0.900195 6.2998L1.8002 6.2998Z" fill="currentColor" />
    </svg>
    </>
},
{
    'space-between': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.450195 5.3002C0.450195 5.53889 0.545016 5.76781 0.713799 5.93659C0.882582 6.10537 1.1115 6.2002 1.3502 6.2002L1.8002 6.2002C2.03889 6.2002 2.26781 6.10537 2.43659 5.93659C2.60537 5.76781 2.7002 5.53889 2.7002 5.3002L2.7002 1.7002C2.7002 1.4615 2.60537 1.23258 2.43659 1.0638C2.26781 0.895016 2.03889 0.800195 1.8002 0.800195L1.3502 0.800195C1.1115 0.800195 0.882582 0.895016 0.713799 1.0638C0.545016 1.23258 0.450195 1.4615 0.450195 1.7002L0.450195 5.3002Z" fill="currentColor" />
        <path d="M6.2998 5.3002C6.2998 5.53889 6.39463 5.76781 6.56341 5.93659C6.73219 6.10537 6.96111 6.2002 7.1998 6.2002L7.64981 6.2002C7.8885 6.2002 8.11742 6.10537 8.2862 5.93659C8.45498 5.76781 8.54981 5.53889 8.54981 5.3002L8.54981 1.7002C8.54981 1.4615 8.45498 1.23258 8.2862 1.0638C8.11742 0.895016 7.8885 0.800195 7.6498 0.800195L7.1998 0.800195C6.96111 0.800195 6.73219 0.895016 6.56341 1.0638C6.39463 1.23258 6.2998 1.4615 6.2998 1.7002L6.2998 5.3002Z" fill="currentColor" />
    </svg>
    </>
},
{
    'space-around': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.3748 6.1998C2.55383 6.1998 2.72551 6.12869 2.8521 6.0021C2.97869 5.87551 3.0498 5.70383 3.0498 5.52481V1.4748C3.0498 1.29578 2.97869 1.12409 2.8521 0.997508C2.72551 0.87092 2.55383 0.799805 2.3748 0.799805H1.4748C1.29578 0.799805 1.12409 0.87092 0.997507 0.997508C0.87092 1.12409 0.799805 1.29578 0.799805 1.4748V5.52481C0.799805 5.70383 0.87092 5.87551 0.997507 6.0021C1.12409 6.12869 1.29578 6.1998 1.4748 6.1998H2.3748Z" fill="currentColor" />
        <path d="M5.5252 6.1998C5.70422 6.1998 5.87591 6.12869 6.00249 6.0021C6.12908 5.87551 6.2002 5.70383 6.2002 5.52481V1.4748C6.2002 1.29578 6.12908 1.12409 6.00249 0.997508C5.87591 0.87092 5.70422 0.799805 5.5252 0.799805H4.6252C4.44617 0.799805 4.27449 0.87092 4.1479 0.997508C4.02131 1.12409 3.9502 1.29578 3.9502 1.4748V5.52481C3.9502 5.70383 4.02131 5.87551 4.1479 6.0021C4.27449 6.12869 4.44617 6.1998 4.6252 6.1998H5.5252Z" fill="currentColor" />
    </svg>
    </>
},
{
    'space-evenly': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.8002 6.1998C2.03889 6.1998 2.26781 6.10498 2.43659 5.9362C2.60537 5.76742 2.7002 5.5385 2.7002 5.2998V1.6998C2.7002 1.46111 2.60537 1.23219 2.43659 1.06341C2.26781 0.894626 2.03889 0.799805 1.8002 0.799805H1.3502C1.1115 0.799805 0.882582 0.894626 0.713799 1.06341C0.545016 1.23219 0.450195 1.46111 0.450195 1.6998V5.2998C0.450195 5.5385 0.545016 5.76742 0.713799 5.9362C0.882582 6.10498 1.1115 6.1998 1.3502 6.1998H1.8002Z" fill="currentColor" />
        <path d="M4.50039 6.1998C4.73909 6.1998 4.968 6.10498 5.13679 5.9362C5.30557 5.76742 5.40039 5.5385 5.40039 5.2998V1.6998C5.40039 1.46111 5.30557 1.23219 5.13679 1.06341C4.968 0.894626 4.73909 0.799805 4.50039 0.799805H4.05039C3.8117 0.799805 3.58278 0.894626 3.41399 1.06341C3.24521 1.23219 3.15039 1.46111 3.15039 1.6998V5.2998C3.15039 5.5385 3.24521 5.76742 3.41399 5.9362C3.58278 6.10498 3.8117 6.1998 4.05039 6.1998H4.50039Z" fill="currentColor" />
        <path d="M7.20059 6.1998C7.43928 6.1998 7.6682 6.10498 7.83698 5.9362C8.00576 5.76742 8.10059 5.5385 8.10059 5.2998V1.6998C8.10059 1.46111 8.00576 1.23219 7.83698 1.06341C7.6682 0.894626 7.43928 0.799805 7.20059 0.799805H6.75059C6.51189 0.799805 6.28297 0.894626 6.11419 1.06341C5.94541 1.23219 5.85059 1.46111 5.85059 1.6998V5.2998C5.85059 5.5385 5.94541 5.76742 6.11419 5.9362C6.28297 6.10498 6.51189 6.1998 6.75059 6.1998H7.20059Z" fill="currentColor" />
    </svg>
    </>
}];

const alignItems = [{
    'flex-start': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.62461 0.849609C0.564936 0.849609 0.507707 0.873314 0.465511 0.91551C0.423315 0.957706 0.39961 1.01494 0.39961 1.07461C0.39961 1.13428 0.423315 1.19151 0.465511 1.23371C0.507707 1.2759 0.564936 1.29961 0.62461 1.29961L7.37461 1.29961C7.43428 1.29961 7.49151 1.2759 7.53371 1.23371C7.5759 1.19151 7.59961 1.13428 7.59961 1.07461C7.59961 1.01494 7.5759 0.957706 7.53371 0.91551C7.49151 0.873315 7.43428 0.849609 7.37461 0.849609L0.62461 0.849609Z" fill="currentColor" />
        <path d="M6.69922 3.09922C6.69922 2.86052 6.6044 2.63161 6.43561 2.46282C6.26683 2.29404 6.03791 2.19922 5.79922 2.19922L2.19922 2.19922C1.96052 2.19922 1.73161 2.29404 1.56282 2.46282C1.39404 2.63161 1.29922 2.86052 1.29922 3.09922L1.29922 3.99922C1.29922 4.23791 1.39404 4.46683 1.56282 4.63561C1.73161 4.8044 1.96052 4.89922 2.19922 4.89922L5.79922 4.89922C6.03791 4.89922 6.26683 4.8044 6.43561 4.63561C6.6044 4.46683 6.69922 4.23791 6.69922 3.99922L6.69922 3.09922Z" fill="currentColor" />
    </svg>

    </>
},
{
    'center': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.39961 1.07461C0.39961 1.01494 0.423315 0.957706 0.465511 0.91551C0.507707 0.873314 0.564936 0.849609 0.62461 0.849609L7.37461 0.849609C7.43428 0.849609 7.49151 0.873315 7.53371 0.91551C7.5759 0.957706 7.59961 1.01494 7.59961 1.07461C7.59961 1.13428 7.5759 1.19151 7.53371 1.23371C7.49151 1.2759 7.43428 1.29961 7.37461 1.29961L0.62461 1.29961C0.564936 1.29961 0.507707 1.2759 0.465511 1.23371C0.423315 1.19151 0.39961 1.13428 0.39961 1.07461Z" fill="currentColor" />
        <path d="M0.62461 6.69922C0.564936 6.69922 0.507707 6.72292 0.465511 6.76512C0.423315 6.80731 0.39961 6.86454 0.39961 6.92422C0.39961 6.98389 0.423315 7.04112 0.465511 7.08332C0.507707 7.12551 0.564936 7.14922 0.62461 7.14922L7.37461 7.14922C7.43428 7.14922 7.49151 7.12551 7.53371 7.08332C7.5759 7.04112 7.59961 6.98389 7.59961 6.92422C7.59961 6.86454 7.5759 6.80732 7.53371 6.76512C7.49151 6.72292 7.43428 6.69922 7.37461 6.69922L0.62461 6.69922Z" fill="currentColor" />
        <path d="M6.69922 3.54941C6.69922 3.31072 6.6044 3.0818 6.43561 2.91302C6.26683 2.74424 6.03791 2.64941 5.79922 2.64941L2.19922 2.64941C1.96052 2.64941 1.73161 2.74423 1.56282 2.91302C1.39404 3.0818 1.29922 3.31072 1.29922 3.54941L1.29922 4.44941C1.29922 4.68811 1.39404 4.91703 1.56282 5.08581C1.73161 5.25459 1.96052 5.34941 2.19922 5.34941L5.79922 5.34941C6.03791 5.34941 6.26683 5.25459 6.43561 5.08581C6.6044 4.91703 6.69922 4.68811 6.69922 4.44941L6.69922 3.54941Z" fill="currentColor" />
    </svg>

    </>
},
{
    'flex-end': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.7748 4.0498C7.83448 4.0498 7.89171 4.0261 7.9339 3.9839C7.9761 3.94171 7.9998 3.88448 7.9998 3.8248C7.9998 3.76513 7.9761 3.7079 7.9339 3.66571C7.89171 3.62351 7.83448 3.5998 7.7748 3.5998L1.0248 3.5998C0.965131 3.5998 0.907901 3.62351 0.865706 3.66571C0.82351 3.7079 0.799805 3.76513 0.799805 3.8248C0.799805 3.88448 0.82351 3.94171 0.865706 3.9839C0.907901 4.0261 0.965131 4.0498 1.0248 4.0498L7.7748 4.0498Z" fill="currentColor" />
        <path d="M1.7002 1.8002C1.7002 2.03889 1.79502 2.26781 1.9638 2.43659C2.13258 2.60537 2.3615 2.7002 2.6002 2.7002L6.2002 2.7002C6.43889 2.7002 6.66781 2.60537 6.83659 2.43659C7.00537 2.26781 7.1002 2.03889 7.1002 1.8002L7.1002 0.900195C7.1002 0.6615 7.00537 0.432582 6.83659 0.263799C6.66781 0.0950163 6.43889 0.000195058 6.2002 0.000195068L2.6002 0.000195225C2.3615 0.000195236 2.13258 0.0950165 1.9638 0.263799C1.79502 0.432582 1.7002 0.6615 1.7002 0.900195L1.7002 1.8002Z" fill="currentColor" />
    </svg>

    </>
},
{
    'space-between': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.1998 0.950195C0.96111 0.950195 0.732191 1.04502 0.563409 1.2138C0.394626 1.38258 0.299805 1.6115 0.299805 1.8502L0.299805 2.3002C0.299805 2.53889 0.394626 2.76781 0.563409 2.93659C0.732191 3.10537 0.96111 3.2002 1.1998 3.2002L4.7998 3.2002C5.0385 3.2002 5.26742 3.10537 5.4362 2.93659C5.60498 2.76781 5.6998 2.53889 5.6998 2.3002L5.6998 1.8502C5.6998 1.6115 5.60498 1.38258 5.4362 1.2138C5.26742 1.04502 5.0385 0.950195 4.7998 0.950195L1.1998 0.950195Z" fill="currentColor" />
        <path d="M1.1998 6.7998C0.96111 6.7998 0.732191 6.89463 0.563409 7.06341C0.394626 7.23219 0.299805 7.46111 0.299805 7.6998L0.299805 8.14981C0.299805 8.3885 0.394626 8.61742 0.563409 8.7862C0.732191 8.95498 0.96111 9.04981 1.1998 9.04981L4.7998 9.04981C5.0385 9.04981 5.26742 8.95498 5.4362 8.7862C5.60498 8.61742 5.6998 8.3885 5.6998 8.14981L5.6998 7.6998C5.6998 7.46111 5.60498 7.23219 5.4362 7.06341C5.26742 6.89463 5.0385 6.7998 4.7998 6.7998L1.1998 6.7998Z" fill="currentColor" />
    </svg>

    </>
},
{
    'space-around': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.300195 1.8748C0.300195 2.05383 0.371311 2.22551 0.497898 2.3521C0.624485 2.47869 0.796174 2.5498 0.975195 2.5498L5.0252 2.5498C5.20422 2.5498 5.37591 2.47869 5.50249 2.3521C5.62908 2.22551 5.7002 2.05383 5.7002 1.8748L5.7002 0.974805C5.7002 0.795783 5.62908 0.624095 5.50249 0.497507C5.37591 0.37092 5.20422 0.299805 5.0252 0.299805L0.975195 0.299804C0.796174 0.299804 0.624485 0.37092 0.497898 0.497507C0.371311 0.624094 0.300195 0.795783 0.300195 0.974804L0.300195 1.8748Z" fill="currentColor" />
        <path d="M0.300195 5.0252C0.300195 5.20422 0.371311 5.37591 0.497898 5.50249C0.624485 5.62908 0.796174 5.7002 0.975195 5.7002L5.0252 5.7002C5.20422 5.7002 5.37591 5.62908 5.50249 5.50249C5.62908 5.37591 5.7002 5.20422 5.7002 5.0252L5.7002 4.1252C5.7002 3.94617 5.62908 3.77449 5.50249 3.6479C5.37591 3.52131 5.20422 3.4502 5.0252 3.4502L0.975195 3.4502C0.796174 3.4502 0.624485 3.52131 0.497898 3.6479C0.371311 3.77448 0.300195 3.94617 0.300195 4.1252L0.300195 5.0252Z" fill="currentColor" />
    </svg>

    </>
},
{
    'space-evenly': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.300195 2.30019C0.300195 2.53889 0.395017 2.76781 0.563799 2.93659C0.732582 3.10537 0.9615 3.2002 1.2002 3.2002L4.8002 3.2002C5.03889 3.2002 5.26781 3.10537 5.43659 2.93659C5.60537 2.76781 5.7002 2.53889 5.7002 2.3002L5.7002 1.8502C5.7002 1.6115 5.60537 1.38258 5.43659 1.2138C5.26781 1.04502 5.03889 0.950195 4.8002 0.950195L1.2002 0.950195C0.9615 0.950195 0.732582 1.04502 0.563799 1.2138C0.395017 1.38258 0.300195 1.6115 0.300195 1.8502L0.300195 2.30019Z" fill="currentColor" />
        <path d="M0.300195 5.00039C0.300195 5.23909 0.395017 5.468 0.563799 5.63679C0.732582 5.80557 0.9615 5.90039 1.2002 5.90039L4.8002 5.90039C5.03889 5.90039 5.26781 5.80557 5.43659 5.63679C5.60537 5.468 5.7002 5.23909 5.7002 5.00039L5.7002 4.55039C5.7002 4.3117 5.60537 4.08278 5.43659 3.91399C5.26781 3.74521 5.03889 3.65039 4.8002 3.65039L1.2002 3.65039C0.9615 3.65039 0.732582 3.74521 0.563799 3.91399C0.395017 4.08278 0.300195 4.3117 0.300195 4.55039L0.300195 5.00039Z" fill="currentColor" />
        <path d="M0.300195 7.70059C0.300195 7.93928 0.395017 8.1682 0.563799 8.33698C0.732582 8.50576 0.9615 8.60059 1.2002 8.60059L4.8002 8.60059C5.03889 8.60059 5.26781 8.50576 5.43659 8.33698C5.60537 8.1682 5.7002 7.93928 5.7002 7.70059L5.7002 7.25059C5.7002 7.01189 5.60537 6.78297 5.43659 6.61419C5.26781 6.44541 5.03889 6.35059 4.8002 6.35059L1.2002 6.35059C0.9615 6.35059 0.732582 6.44541 0.563799 6.61419C0.395017 6.78297 0.300195 7.01189 0.300195 7.25059L0.300195 7.70059Z" fill="currentColor" />
    </svg>

    </>
}];


const alignContents = [{
    'flex-start': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.62461 0.849609C0.564936 0.849609 0.507707 0.873314 0.465511 0.91551C0.423315 0.957706 0.39961 1.01494 0.39961 1.07461C0.39961 1.13428 0.423315 1.19151 0.465511 1.23371C0.507707 1.2759 0.564936 1.29961 0.62461 1.29961L7.37461 1.29961C7.43428 1.29961 7.49151 1.2759 7.53371 1.23371C7.5759 1.19151 7.59961 1.13428 7.59961 1.07461C7.59961 1.01494 7.5759 0.957706 7.53371 0.91551C7.49151 0.873315 7.43428 0.849609 7.37461 0.849609L0.62461 0.849609Z" fill="currentColor" />
        <path d="M6.69922 3.09922C6.69922 2.86052 6.6044 2.63161 6.43561 2.46282C6.26683 2.29404 6.03791 2.19922 5.79922 2.19922L2.19922 2.19922C1.96052 2.19922 1.73161 2.29404 1.56282 2.46282C1.39404 2.63161 1.29922 2.86052 1.29922 3.09922L1.29922 3.99922C1.29922 4.23791 1.39404 4.46683 1.56282 4.63561C1.73161 4.8044 1.96052 4.89922 2.19922 4.89922L5.79922 4.89922C6.03791 4.89922 6.26683 4.8044 6.43561 4.63561C6.6044 4.46683 6.69922 4.23791 6.69922 3.99922L6.69922 3.09922Z" fill="currentColor" />
    </svg>

    </>
},
{
    'center': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.39961 1.07461C0.39961 1.01494 0.423315 0.957706 0.465511 0.91551C0.507707 0.873314 0.564936 0.849609 0.62461 0.849609L7.37461 0.849609C7.43428 0.849609 7.49151 0.873315 7.53371 0.91551C7.5759 0.957706 7.59961 1.01494 7.59961 1.07461C7.59961 1.13428 7.5759 1.19151 7.53371 1.23371C7.49151 1.2759 7.43428 1.29961 7.37461 1.29961L0.62461 1.29961C0.564936 1.29961 0.507707 1.2759 0.465511 1.23371C0.423315 1.19151 0.39961 1.13428 0.39961 1.07461Z" fill="currentColor" />
        <path d="M0.62461 6.69922C0.564936 6.69922 0.507707 6.72292 0.465511 6.76512C0.423315 6.80731 0.39961 6.86454 0.39961 6.92422C0.39961 6.98389 0.423315 7.04112 0.465511 7.08332C0.507707 7.12551 0.564936 7.14922 0.62461 7.14922L7.37461 7.14922C7.43428 7.14922 7.49151 7.12551 7.53371 7.08332C7.5759 7.04112 7.59961 6.98389 7.59961 6.92422C7.59961 6.86454 7.5759 6.80732 7.53371 6.76512C7.49151 6.72292 7.43428 6.69922 7.37461 6.69922L0.62461 6.69922Z" fill="currentColor" />
        <path d="M6.69922 3.54941C6.69922 3.31072 6.6044 3.0818 6.43561 2.91302C6.26683 2.74424 6.03791 2.64941 5.79922 2.64941L2.19922 2.64941C1.96052 2.64941 1.73161 2.74423 1.56282 2.91302C1.39404 3.0818 1.29922 3.31072 1.29922 3.54941L1.29922 4.44941C1.29922 4.68811 1.39404 4.91703 1.56282 5.08581C1.73161 5.25459 1.96052 5.34941 2.19922 5.34941L5.79922 5.34941C6.03791 5.34941 6.26683 5.25459 6.43561 5.08581C6.6044 4.91703 6.69922 4.68811 6.69922 4.44941L6.69922 3.54941Z" fill="currentColor" />
    </svg>

    </>
},
{
    'flex-end': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.7748 4.0498C7.83448 4.0498 7.89171 4.0261 7.9339 3.9839C7.9761 3.94171 7.9998 3.88448 7.9998 3.8248C7.9998 3.76513 7.9761 3.7079 7.9339 3.66571C7.89171 3.62351 7.83448 3.5998 7.7748 3.5998L1.0248 3.5998C0.965131 3.5998 0.907901 3.62351 0.865706 3.66571C0.82351 3.7079 0.799805 3.76513 0.799805 3.8248C0.799805 3.88448 0.82351 3.94171 0.865706 3.9839C0.907901 4.0261 0.965131 4.0498 1.0248 4.0498L7.7748 4.0498Z" fill="currentColor" />
        <path d="M1.7002 1.8002C1.7002 2.03889 1.79502 2.26781 1.9638 2.43659C2.13258 2.60537 2.3615 2.7002 2.6002 2.7002L6.2002 2.7002C6.43889 2.7002 6.66781 2.60537 6.83659 2.43659C7.00537 2.26781 7.1002 2.03889 7.1002 1.8002L7.1002 0.900195C7.1002 0.6615 7.00537 0.432582 6.83659 0.263799C6.66781 0.0950163 6.43889 0.000195058 6.2002 0.000195068L2.6002 0.000195225C2.3615 0.000195236 2.13258 0.0950165 1.9638 0.263799C1.79502 0.432582 1.7002 0.6615 1.7002 0.900195L1.7002 1.8002Z" fill="currentColor" />
    </svg>

    </>
},
{
    'space-between': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.1998 0.950195C0.96111 0.950195 0.732191 1.04502 0.563409 1.2138C0.394626 1.38258 0.299805 1.6115 0.299805 1.8502L0.299805 2.3002C0.299805 2.53889 0.394626 2.76781 0.563409 2.93659C0.732191 3.10537 0.96111 3.2002 1.1998 3.2002L4.7998 3.2002C5.0385 3.2002 5.26742 3.10537 5.4362 2.93659C5.60498 2.76781 5.6998 2.53889 5.6998 2.3002L5.6998 1.8502C5.6998 1.6115 5.60498 1.38258 5.4362 1.2138C5.26742 1.04502 5.0385 0.950195 4.7998 0.950195L1.1998 0.950195Z" fill="currentColor" />
        <path d="M1.1998 6.7998C0.96111 6.7998 0.732191 6.89463 0.563409 7.06341C0.394626 7.23219 0.299805 7.46111 0.299805 7.6998L0.299805 8.14981C0.299805 8.3885 0.394626 8.61742 0.563409 8.7862C0.732191 8.95498 0.96111 9.04981 1.1998 9.04981L4.7998 9.04981C5.0385 9.04981 5.26742 8.95498 5.4362 8.7862C5.60498 8.61742 5.6998 8.3885 5.6998 8.14981L5.6998 7.6998C5.6998 7.46111 5.60498 7.23219 5.4362 7.06341C5.26742 6.89463 5.0385 6.7998 4.7998 6.7998L1.1998 6.7998Z" fill="currentColor" />
    </svg>

    </>
},
{
    'space-around': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.300195 1.8748C0.300195 2.05383 0.371311 2.22551 0.497898 2.3521C0.624485 2.47869 0.796174 2.5498 0.975195 2.5498L5.0252 2.5498C5.20422 2.5498 5.37591 2.47869 5.50249 2.3521C5.62908 2.22551 5.7002 2.05383 5.7002 1.8748L5.7002 0.974805C5.7002 0.795783 5.62908 0.624095 5.50249 0.497507C5.37591 0.37092 5.20422 0.299805 5.0252 0.299805L0.975195 0.299804C0.796174 0.299804 0.624485 0.37092 0.497898 0.497507C0.371311 0.624094 0.300195 0.795783 0.300195 0.974804L0.300195 1.8748Z" fill="currentColor" />
        <path d="M0.300195 5.0252C0.300195 5.20422 0.371311 5.37591 0.497898 5.50249C0.624485 5.62908 0.796174 5.7002 0.975195 5.7002L5.0252 5.7002C5.20422 5.7002 5.37591 5.62908 5.50249 5.50249C5.62908 5.37591 5.7002 5.20422 5.7002 5.0252L5.7002 4.1252C5.7002 3.94617 5.62908 3.77449 5.50249 3.6479C5.37591 3.52131 5.20422 3.4502 5.0252 3.4502L0.975195 3.4502C0.796174 3.4502 0.624485 3.52131 0.497898 3.6479C0.371311 3.77448 0.300195 3.94617 0.300195 4.1252L0.300195 5.0252Z" fill="currentColor" />
    </svg>

    </>
},
{
    'space-evenly': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.300195 2.30019C0.300195 2.53889 0.395017 2.76781 0.563799 2.93659C0.732582 3.10537 0.9615 3.2002 1.2002 3.2002L4.8002 3.2002C5.03889 3.2002 5.26781 3.10537 5.43659 2.93659C5.60537 2.76781 5.7002 2.53889 5.7002 2.3002L5.7002 1.8502C5.7002 1.6115 5.60537 1.38258 5.43659 1.2138C5.26781 1.04502 5.03889 0.950195 4.8002 0.950195L1.2002 0.950195C0.9615 0.950195 0.732582 1.04502 0.563799 1.2138C0.395017 1.38258 0.300195 1.6115 0.300195 1.8502L0.300195 2.30019Z" fill="currentColor" />
        <path d="M0.300195 5.00039C0.300195 5.23909 0.395017 5.468 0.563799 5.63679C0.732582 5.80557 0.9615 5.90039 1.2002 5.90039L4.8002 5.90039C5.03889 5.90039 5.26781 5.80557 5.43659 5.63679C5.60537 5.468 5.7002 5.23909 5.7002 5.00039L5.7002 4.55039C5.7002 4.3117 5.60537 4.08278 5.43659 3.91399C5.26781 3.74521 5.03889 3.65039 4.8002 3.65039L1.2002 3.65039C0.9615 3.65039 0.732582 3.74521 0.563799 3.91399C0.395017 4.08278 0.300195 4.3117 0.300195 4.55039L0.300195 5.00039Z" fill="currentColor" />
        <path d="M0.300195 7.70059C0.300195 7.93928 0.395017 8.1682 0.563799 8.33698C0.732582 8.50576 0.9615 8.60059 1.2002 8.60059L4.8002 8.60059C5.03889 8.60059 5.26781 8.50576 5.43659 8.33698C5.60537 8.1682 5.7002 7.93928 5.7002 7.70059L5.7002 7.25059C5.7002 7.01189 5.60537 6.78297 5.43659 6.61419C5.26781 6.44541 5.03889 6.35059 4.8002 6.35059L1.2002 6.35059C0.9615 6.35059 0.732582 6.44541 0.563799 6.61419C0.395017 6.78297 0.300195 7.01189 0.300195 7.25059L0.300195 7.70059Z" fill="currentColor" />
    </svg>

    </>
}, {
    'stretch': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.4668 7.73333C7.4668 7.66261 7.4387 7.59478 7.38869 7.54477C7.33868 7.49476 7.27085 7.46667 7.20013 7.46667L4.80013 7.46667L4.80013 0.533333L7.20013 0.533333C7.27085 0.533333 7.33868 0.505238 7.38869 0.455229C7.4387 0.405219 7.4668 0.337391 7.4668 0.266667C7.4668 0.195942 7.4387 0.128115 7.38869 0.0781049C7.33868 0.0280952 7.27085 -8.56491e-09 7.20013 -1.16564e-08L0.800131 -2.91409e-07C0.729406 -2.94501e-07 0.661578 0.0280949 0.611568 0.0781046C0.561559 0.128114 0.533464 0.195942 0.533464 0.266666C0.533464 0.337391 0.561559 0.405219 0.611568 0.455228C0.661578 0.505238 0.729406 0.533333 0.800131 0.533333L3.20013 0.533333L3.20013 7.46667L0.800131 7.46667C0.729406 7.46667 0.661578 7.49476 0.611568 7.54477C0.561558 7.59478 0.533464 7.66261 0.533464 7.73333C0.533464 7.80406 0.561558 7.87189 0.611568 7.9219C0.661578 7.9719 0.729406 8 0.80013 8L7.20013 8C7.27085 8 7.33868 7.97191 7.38869 7.9219C7.4387 7.87189 7.4668 7.80406 7.4668 7.73333Z" fill="currentColor" />
    </svg>
    </>
}];


const selfAligns = [{
    'flex-start': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.62461 0.849609C0.564936 0.849609 0.507707 0.873314 0.465511 0.91551C0.423315 0.957706 0.39961 1.01494 0.39961 1.07461C0.39961 1.13428 0.423315 1.19151 0.465511 1.23371C0.507707 1.2759 0.564936 1.29961 0.62461 1.29961L7.37461 1.29961C7.43428 1.29961 7.49151 1.2759 7.53371 1.23371C7.5759 1.19151 7.59961 1.13428 7.59961 1.07461C7.59961 1.01494 7.5759 0.957706 7.53371 0.91551C7.49151 0.873315 7.43428 0.849609 7.37461 0.849609L0.62461 0.849609Z" fill="currentColor" />
        <path d="M6.69922 3.09922C6.69922 2.86052 6.6044 2.63161 6.43561 2.46282C6.26683 2.29404 6.03791 2.19922 5.79922 2.19922L2.19922 2.19922C1.96052 2.19922 1.73161 2.29404 1.56282 2.46282C1.39404 2.63161 1.29922 2.86052 1.29922 3.09922L1.29922 3.99922C1.29922 4.23791 1.39404 4.46683 1.56282 4.63561C1.73161 4.8044 1.96052 4.89922 2.19922 4.89922L5.79922 4.89922C6.03791 4.89922 6.26683 4.8044 6.43561 4.63561C6.6044 4.46683 6.69922 4.23791 6.69922 3.99922L6.69922 3.09922Z" fill="currentColor" />
    </svg>

    </>
},
{
    'center': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.39961 1.07461C0.39961 1.01494 0.423315 0.957706 0.465511 0.91551C0.507707 0.873314 0.564936 0.849609 0.62461 0.849609L7.37461 0.849609C7.43428 0.849609 7.49151 0.873315 7.53371 0.91551C7.5759 0.957706 7.59961 1.01494 7.59961 1.07461C7.59961 1.13428 7.5759 1.19151 7.53371 1.23371C7.49151 1.2759 7.43428 1.29961 7.37461 1.29961L0.62461 1.29961C0.564936 1.29961 0.507707 1.2759 0.465511 1.23371C0.423315 1.19151 0.39961 1.13428 0.39961 1.07461Z" fill="currentColor" />
        <path d="M0.62461 6.69922C0.564936 6.69922 0.507707 6.72292 0.465511 6.76512C0.423315 6.80731 0.39961 6.86454 0.39961 6.92422C0.39961 6.98389 0.423315 7.04112 0.465511 7.08332C0.507707 7.12551 0.564936 7.14922 0.62461 7.14922L7.37461 7.14922C7.43428 7.14922 7.49151 7.12551 7.53371 7.08332C7.5759 7.04112 7.59961 6.98389 7.59961 6.92422C7.59961 6.86454 7.5759 6.80732 7.53371 6.76512C7.49151 6.72292 7.43428 6.69922 7.37461 6.69922L0.62461 6.69922Z" fill="currentColor" />
        <path d="M6.69922 3.54941C6.69922 3.31072 6.6044 3.0818 6.43561 2.91302C6.26683 2.74424 6.03791 2.64941 5.79922 2.64941L2.19922 2.64941C1.96052 2.64941 1.73161 2.74423 1.56282 2.91302C1.39404 3.0818 1.29922 3.31072 1.29922 3.54941L1.29922 4.44941C1.29922 4.68811 1.39404 4.91703 1.56282 5.08581C1.73161 5.25459 1.96052 5.34941 2.19922 5.34941L5.79922 5.34941C6.03791 5.34941 6.26683 5.25459 6.43561 5.08581C6.6044 4.91703 6.69922 4.68811 6.69922 4.44941L6.69922 3.54941Z" fill="currentColor" />
    </svg>

    </>
},
{
    'flex-end': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.7748 4.0498C7.83448 4.0498 7.89171 4.0261 7.9339 3.9839C7.9761 3.94171 7.9998 3.88448 7.9998 3.8248C7.9998 3.76513 7.9761 3.7079 7.9339 3.66571C7.89171 3.62351 7.83448 3.5998 7.7748 3.5998L1.0248 3.5998C0.965131 3.5998 0.907901 3.62351 0.865706 3.66571C0.82351 3.7079 0.799805 3.76513 0.799805 3.8248C0.799805 3.88448 0.82351 3.94171 0.865706 3.9839C0.907901 4.0261 0.965131 4.0498 1.0248 4.0498L7.7748 4.0498Z" fill="currentColor" />
        <path d="M1.7002 1.8002C1.7002 2.03889 1.79502 2.26781 1.9638 2.43659C2.13258 2.60537 2.3615 2.7002 2.6002 2.7002L6.2002 2.7002C6.43889 2.7002 6.66781 2.60537 6.83659 2.43659C7.00537 2.26781 7.1002 2.03889 7.1002 1.8002L7.1002 0.900195C7.1002 0.6615 7.00537 0.432582 6.83659 0.263799C6.66781 0.0950163 6.43889 0.000195058 6.2002 0.000195068L2.6002 0.000195225C2.3615 0.000195236 2.13258 0.0950165 1.9638 0.263799C1.79502 0.432582 1.7002 0.6615 1.7002 0.900195L1.7002 1.8002Z" fill="currentColor" />
    </svg>

    </>
}, {
    'stretch': <><svg stroke="currentColor" stroke-width="0" height="medium" width="medium" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.4668 7.73333C7.4668 7.66261 7.4387 7.59478 7.38869 7.54477C7.33868 7.49476 7.27085 7.46667 7.20013 7.46667L4.80013 7.46667L4.80013 0.533333L7.20013 0.533333C7.27085 0.533333 7.33868 0.505238 7.38869 0.455229C7.4387 0.405219 7.4668 0.337391 7.4668 0.266667C7.4668 0.195942 7.4387 0.128115 7.38869 0.0781049C7.33868 0.0280952 7.27085 -8.56491e-09 7.20013 -1.16564e-08L0.800131 -2.91409e-07C0.729406 -2.94501e-07 0.661578 0.0280949 0.611568 0.0781046C0.561559 0.128114 0.533464 0.195942 0.533464 0.266666C0.533464 0.337391 0.561559 0.405219 0.611568 0.455228C0.661578 0.505238 0.729406 0.533333 0.800131 0.533333L3.20013 0.533333L3.20013 7.46667L0.800131 7.46667C0.729406 7.46667 0.661578 7.49476 0.611568 7.54477C0.561558 7.59478 0.533464 7.66261 0.533464 7.73333C0.533464 7.80406 0.561558 7.87189 0.611568 7.9219C0.661578 7.9719 0.729406 8 0.80013 8L7.20013 8C7.27085 8 7.33868 7.97191 7.38869 7.9219C7.4387 7.87189 7.4668 7.80406 7.4668 7.73333Z" fill="currentColor" />
    </svg>
    </>
}];

export default function Flex({ style, properties, onChangeStyle, stylePriority, setStylePriority }) {

    const [rowUnit, setRowUnit] = useState(units[0]);
    const [colUnit, setColUnit] = useState(units[0]);


    useEffect(() => {
        if (!properties) return;

        setRowUnit(getUnit(`row-gap`) || 'px');
        setColUnit(getUnit(`column-gap`) || 'px');

    }, [properties]);

    const getUnit = (property) => {
        return properties[property]?.unit;
    };



    const onChangeDirection = (value) => {

        if (!properties['flex-direction'])
            properties['flex-direction'] = {};

        properties['flex-direction'].value = value;


        style.setProperty('flex-direction', value, stylePriority.flexDirection);
        onChangeStyle();
        setStylePriority({ ...stylePriority });

    }

    const onChangeWrap = (value) => {
        if (!properties['flex-wrap'])
            properties['flex-wrap'] = {};

        properties['flex-wrap'].value = value;
        style.setProperty('flex-wrap', value, stylePriority.flexWrap);
        onChangeStyle();
        setStylePriority({ ...stylePriority });

    }

    const onChangeJustifyContent = (value) => {
        if (!properties['justify-content'])
            properties['justify-content'] = {};

        properties['justify-content'].value = value;
        style.setProperty('justify-content', value, stylePriority.justifyContent);
        onChangeStyle();
        setStylePriority({ ...stylePriority });

    }

    const onChangeAlignItem = (value) => {
        if (!properties['align-items'])
            properties['align-items'] = {};

        properties['align-items'].value = value;
        style.setProperty('align-items', value, stylePriority.alignItems);
        onChangeStyle();
        setStylePriority({ ...stylePriority });
    }

    const onChangeAlignContent = (value) => {
        if (!properties['align-content'])
            properties['align-content'] = {};

        properties['align-content'].value = value;
        style.setProperty('align-content', value, stylePriority.alignContent);
        onChangeStyle();
        setStylePriority({ ...stylePriority });
    }



    const onChange = (dataName, name, value) => {


        let unit = '';
        if (name === 'rowGap') {
            value = value + rowUnit;
            unit = rowUnit;
        }
        else
            if (name === 'columnGap') {
                value = value + colUnit;
                unit = colUnit;

            }

        if (!properties[dataName])
            properties[dataName] = {};

        properties[dataName].value = value;
        properties[dataName].unit = unit;


        style.setProperty(dataName, value, stylePriority[name]);
        onChangeStyle();
        setStylePriority({ ...stylePriority });
    }


    const onChangeRowGapUnit = (value) => {
        const val = `${parseFloat(style.rowGap || 0) + value}`;

        style.setProperty('row-gap', val, stylePriority.rowGap);


        if (!properties['row-gap'])
            properties['row-gap'] = {};

        properties['row-gap'].unit = value;
        properties['row-gap'].value = val;


        setRowUnit(value);
        onChangeStyle();


    }

    const onChangeColGapUnit = (value) => {
        const val = `${parseFloat(style.columnGap || 0) + value}`;
        style.setProperty('column-gap', val, stylePriority.columnGap);


        if (!properties['column-gap'])
            properties['column-gap'] = {};

        properties['column-gap'].unit = value;
        properties['column-gap'].value = val;

        setColUnit(value);
        onChangeStyle();

    }


    const onChangePriority = (e) => {

        const name = e.target.name;
        const _name = e.target['data-name'];
        const priority = e.target.checked ? 'important' : '';
        if (!properties[_name])
            properties[_name] = {};

        properties[_name].priority = priority;
        style.setProperty(_name, style[name], priority);
        setStylePriority({ ...stylePriority, [name]: priority })
        onChangeStyle();

    }




    return <>
        <div style={{ marginBottom: "10px" }}>
            <strong >Direction:     </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='flexDirection' data-name="flex-direction" checked={stylePriority.flexDirection === 'important'} onChange={onChangePriority}>i</Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeDirection('')

                        if (properties["flex-direction"])
                            delete properties["flex-direction"];
                       onChangeStyle(); setStylePriority({ ...stylePriority, flexDirection: '' });
                        style.removeProperty('flex-direction');
                    }}
                    className="ac-icons-btn"
                />
            </Tooltip></div>
        <div style={{ marginBottom: "10px" }}>
            {flexDirections.map(item => {

                const key = Object.keys(item)[0];

                return <Tooltip title={key}>
                    <Button
                        onClick={(e) => { e.preventDefault(); onChangeDirection(key) }}
                        icon={item[key]}
                        type={key === style.flexDirection ? 'primary' : 'default'}
                    ></Button> {" "}
                </Tooltip>
            })}
        </div>


        <div style={{ marginBottom: "10px" }}>
            <strong >Wrap:     </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='flexWrap' data-name="flex-wrap" checked={stylePriority.flexWrap === 'important'} onChange={onChangePriority}>i</Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeWrap('')
                        
                        if (properties["flex-wrap"])
                            delete properties["flex-wrap"];
                       onChangeStyle(); setStylePriority({ ...stylePriority, flexWrap: '' });
                        style.removeProperty('flex-wrap');
                    }}
                    className="ac-icons-btn"
                />
            </Tooltip></div>
        <div style={{ marginBottom: "10px" }}>
            {flexWrap.map(item => {

                const key = Object.keys(item)[0];

                return <Tooltip title={key}>
                    <Button
                        onClick={(e) => { e.preventDefault(); onChangeWrap(key) }}

                        icon={item[key]}
                        type={key === style.flexWrap ? 'primary' : 'default'}
                    ></Button>  {" "}
                </Tooltip>
            })}

        </div>
        <div style={{ marginBottom: "10px" }}>
            <strong>Row Gap: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }} name="rowGap" data-name="row-gap"
                    checked={stylePriority.rowGap === 'important'} onChange={onChangePriority}>
                    i
                </Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: "right" }}

                    size="small"
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeStyle();
                        if (properties["row-gap"])
                            delete properties["row-gap"];
                       onChangeStyle(); setStylePriority({ ...stylePriority, rowGap: '' });
                        style.removeProperty('row-gap');
                    }}
                    className="ac-icons-btn"
                />
            </Tooltip>
        </div>

        <div style={{ marginBottom: "10px" }}>
            <div>
                {units.map((u) => (
                    <span
                        key={u}
                        onClick={(e) => {
                            e.preventDefault();
                            onChangeRowGapUnit(u);
                        }}
                        style={{ color: u === rowUnit ? '#f88a24' : '', cursor: 'pointer' }}
                    >
                        {u.toUpperCase()}
                    </span>
                ))}
            </div>

            <div style={{ display: 'flex' }}>
                <Slider
                    min={0.3}
                    max={72}
                    step={0.1}

                    value={parseFloat(style.rowGap)}
                    onChange={(value) => { onChange('row-gap', 'rowGap', value); }}
                    style={{ width: '70%' }}
                />
                <InputNumber
                    value={parseFloat(style.rowGap)}
                    onChange={(value) => { onChange('row-gap', 'rowGap', value); }}
                    style={{ width: '30%' }}

                />
            </div>



        </div>


        <div style={{ marginBottom: "10px" }}>
            <strong>Column Gap: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }} name='columnGap' data-name="column-gap" checked={stylePriority.columnGap === 'important'} onChange={onChangePriority}>i</Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeStyle();
                        if (properties["column-gap"])
                            delete properties["column-gap"];
                       onChangeStyle(); setStylePriority({ ...stylePriority, columnGap: '' });
                        style.removeProperty('column-gap');
                    }}
                ></Button>
            </Tooltip>
        </div>
        <div style={{ marginBottom: "10px" }}>
            <div>
                {units.map(u => <span onClick={(e) => { e.preventDefault(); onChangeColGapUnit(u) }}
                    style={{ color: u === colUnit ? '#f88a24' : '', cursor: 'pointer' }}>
                    {u.toUpperCase()}
                </span>)}
            </div>
            <div style={{ display: 'flex' }}>
                <Slider
                    min={0.3}
                    max={72}
                    step={0.1}

                    value={parseFloat(style.columnGap)}
                    onChange={(value) => { onChange('column-gap', 'columnGap', value); }}
                    style={{ width: '70%' }}
                />
                <InputNumber
                    value={parseFloat(style.columnGap)}
                    onChange={(value) => { onChange('column-gap', 'columnGap', value); }}
                    style={{ width: '30%' }}

                />
            </div>

        </div>



        <div style={{ marginBottom: "10px" }}>
            <strong>Justify Content: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='justifyContent' data-name="justify-content" checked={stylePriority.justifyContent === 'important'} onChange={onChangePriority}>i</Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeJustifyContent('')
                        if (properties["justify-content"])
                            delete properties["justify-content"];
                       onChangeStyle(); setStylePriority({ ...stylePriority, justifyContent: '' });
                        style.removeProperty('justify-content');
                    }}
                    className="ac-icons-btn"
                />
            </Tooltip> </div>

            <div style={{ marginBottom: "10px" }}>
            <Select style={{ width: '100%' }} value={style.justifyContent} onChange={onChangeJustifyContent}>
                {justifyContents.map(item => {

                    const key = Object.keys(item)[0];

                    return <Select.Option
                        value={key}
                        label={key}
                    />

                })}
            </Select>
        </div>

        <div style={{ marginBottom: "10px" }}>
            <strong>  Align Items: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='alignItems' data-name="align-items" checked={stylePriority.alignItems === 'important'} onChange={onChangePriority}>i</Checkbox>

            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeAlignItem('')
                        if (properties["align-items"])
                            delete properties["align-items"];
                       onChangeStyle(); setStylePriority({ ...stylePriority, alignItems: '' });
                        style.removeProperty('align-items');
                    }} />
            </Tooltip></div>


            <div style={{ marginBottom: "10px" }}>
            <Select style={{ width: '100%' }} value={style.alignItems} onChange={onChangeAlignItem}>
                {alignItems.map(item => {

                    const key = Object.keys(item)[0];

                    return <Select.Option
                        value={key}
                        label={key}
                    />

                })}
            </Select>
        </div>



        <div style={{ marginBottom: "10px" }}>
            <strong>Align Content: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='alignContent' data-name="align-content" checked={stylePriority.alignContent === 'important'} onChange={onChangePriority}>i</Checkbox>
            </Tooltip>

            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeAlignContent('')
                        if (properties["align-content"])
                            delete properties["align-content"];
                       onChangeStyle(); setStylePriority({ ...stylePriority, alignContent: '' });
                        style.removeProperty('align-content');
                    }}
                />
            </Tooltip></div>



            <div style={{ marginBottom: "10px" }}>
            <Select style={{ width: '100%' }} value={style.alignContent} onChange={onChangeAlignContent}>
                {alignContents.map(item => {

                    const key = Object.keys(item)[0];

                    return <Select.Option
                        value={key}
                        label={key}
                    />

                })}
            </Select>
        </div>

    </>






}

export function FlexChild({ style, properties, onChangeStyle, stylePriority, setStylePriority }) {


    const [flexBasisUnit, setFlexBasisUnit] = useState(units[0]);


    useEffect(() => {
        if (!properties) return;

        setFlexBasisUnit(getUnit(`flex-basis`) || 'px');

    }, [properties]);

    const getUnit = (property) => {
        return properties[property]?.unit;
    };

    const onChangeSelfAlign = (value) => {

        if (!properties['align-self'])
            properties['align-self'] = {};

        properties['align-self'].value = value;
        style.setProperty('align-self', value, stylePriority.alignSelf);
        onChangeStyle();
        setStylePriority({ ...stylePriority });


    }


    const onChange = (dataName, name, value) => {


        let unit = undefined;
        if (name === 'flexBasis') {
            value = value + flexBasisUnit;
            unit = flexBasisUnit;
        }



        if (!properties[dataName])
            properties[dataName] = {};

        properties[dataName].value = value;
        properties[dataName].unit = unit;

        style.setProperty(dataName, value, stylePriority[name]);
        onChangeStyle();
        setStylePriority({ ...stylePriority });

    }



    const onChangeFlexBasisUnit = (value) => {
        const val = `${parseFloat(style.flexBasis || 0) + value}`;
        style.setProperty('flex-basis', val, stylePriority.flexBasis);

        if (!properties['flex-basis'])
            properties['flex-basis'] = {};

        properties['flex-basis'].unit = value;
        properties['flex-basis'].value = val;
        setFlexBasisUnit(value);

        onChangeStyle();


    }

    const onChangePriority = (e) => {

        const name = e.target.name;
        const _name = e.target['data-name'];
        const priority = e.target.checked ? 'important' : '';
        if (!properties[_name])
            properties[_name] = {};

        properties[_name].priority = priority;
        setStylePriority({ ...stylePriority, [name]: priority })

        style.setProperty(_name, style[name], priority);
        onChangeStyle();

    }


    return <>

        <div style={{ marginBottom: "10px" }}>
            <strong>Flex:  </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='flex' data-name="flex" checked={stylePriority.flex === 'important'} onChange={onChangePriority}>i</Checkbox>

            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();

                        if (properties['flex'])
                            delete properties['flex'];

                       onChangeStyle(); setStylePriority({ ...stylePriority, flex: '' });

                        style.removeProperty('flex');
                    }}
                />
            </Tooltip></div>

        <div style={{ marginBottom: "10px" }}>
            <InputNumber
                name="flex"
                data-name="flex" value={style.flexGrow || 0}
                onChange={(value) => onChange('flex', 'flex', value)} />

        </div>

        <div style={{ marginBottom: "10px" }}>
            <strong>Flex Grow:  </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='flexGrow' data-name="flex-grow" checked={stylePriority.flexGrow === 'important'} onChange={onChangePriority}>i</Checkbox>

            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();

                        if (properties['flex-grow'])
                            delete properties['flex-grow'];



                            onChangeStyle(); setStylePriority({ ...stylePriority, flexGrow: '' });
                        style.removeProperty('flex-grow');
                    }}
                />
            </Tooltip></div>

        <div style={{ marginBottom: "10px" }}>
            <InputNumber
                name="flexGrow"
                data-name="flex-grow" value={style.flexGrow || 0}
                onChange={(value) => onChange('flex-grow', 'flexGrow', value)} />

        </div>


        <div style={{ marginBottom: "10px" }}>
            <strong>Flex Shrink: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='flexShrink' data-name="flex-shrink"
                    checked={stylePriority.flexShrink === 'important'}
                    onChange={onChangePriority}>i</Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();

                        if (properties['flex-shrink'])
                            delete properties['flex-shrink'];

                            onChangeStyle(); setStylePriority({ ...stylePriority, flexShrink: '' });
                        style.removeProperty('flex-shrink');
                    }}
                />
            </Tooltip> </div>
        <div style={{ marginBottom: "10px" }}>

            <InputNumber
                name="flexShrink"
                data-name="flex-shrink" value={style.flexShrink || 0}
                onChange={(value) => onChange('flex-shrink', 'flexShrink', value)} />

        </div>



        <div style={{ marginBottom: "10px" }}>
            <strong>Flex Basis: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }} name='flexBasis' data-name="flex-basis" checked={stylePriority.flexBasis === 'important'} onChange={onChangePriority}>i</Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        if (properties['flex-basis'])
                            delete properties['flex-basis'];


                            onChangeStyle(); setStylePriority({ ...stylePriority, flexBasis: '' });
                        style.removeProperty('flex-basis');
                    }}
                ></Button>
            </Tooltip>
        </div>
        <div style={{ marginBottom: "10px" }}>
            <div>
                {units.map(u => <span onClick={(e) => { e.preventDefault(); onChangeFlexBasisUnit(u) }}
                    style={{ color: u === flexBasisUnit ? '#f88a24' : '', cursor: 'pointer' }}>
                    {u.toUpperCase()}
                </span>)}
            </div>
            <div style={{ display: 'flex' }}>
                <Slider
                    min={0.3}
                    max={72}
                    step={0.1}

                    value={parseFloat(style.flexBasis)}
                    onChange={(value) => { onChange('flex-basis', 'flexBasis', value); }}
                    style={{ width: '70%' }}
                />
                <InputNumber
                    value={parseFloat(style.flexBasis)}
                    onChange={(value) => { onChange('flex-basis', 'flexBasis', value); }}
                    style={{ width: '30%' }}

                />
            </div>

        </div>

        <div style={{ marginBottom: "10px" }}>
            <strong>Order: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='order' data-name="order"
                    checked={stylePriority.order === 'important'}
                    onChange={onChangePriority}>
                    i</Checkbox>
            </Tooltip>
            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        if (properties['order'])
                            delete properties['order'];


                            onChangeStyle(); setStylePriority({ ...stylePriority, order: '' });
                        style.removeProperty('order');
                    }}
                />
            </Tooltip> </div>
        <div style={{ marginBottom: "10px" }}>

            <InputNumber
                name="order"
                data-name="order" value={parseFloat(style.order)}
                onChange={(value) => onChange('order', 'order', value)} />

        </div>


        <div style={{ marginBottom: "10px" }}>
            <strong>Self Align: </strong>
            <Tooltip placement="top" title="important">
                <Checkbox style={{ marginLeft: 3 }}
                    name='alignSelf' data-name="align-self" checked={stylePriority.alignSelf === 'important'}
                    onChange={onChangePriority}>i</Checkbox>
            </Tooltip>

            <Tooltip placement="top" title="Reset">
                <Button
                    style={{ float: 'right' }}
                    size='small'
                    icon={<MdRestartAlt size="small" />}
                    onClick={(e) => {
                        e.preventDefault();
                        onChangeSelfAlign("")

                        if (properties['align-self'])
                            delete properties['align-self'];


                            onChangeStyle(); setStylePriority({ ...stylePriority, alignSelf: '' });
                        style.removeProperty('align-self');
                    }}
                />
            </Tooltip></div>



        
        <div style={{ marginBottom: "10px" }}>
            <Select style={{ width: '100%' }} value={style.alignSelf} onChange={onChangeSelfAlign}>
                {selfAligns.map(item => {

                    const key = Object.keys(item)[0];

                    return <Select.Option
                        value={key}
                        label={key}
                    />

                })}
            </Select>
        </div>


    </>


}
