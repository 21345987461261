import {
  Button, Modal, Card, Spin, Alert, Tooltip, InputNumber,
  Radio, Select, Divider, ColorPicker, Layout, Input, Checkbox
} from "antd";
import { useState, useReducer, useEffect, useRef } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';

import { AnimationsIFrame } from "../../Iframe";

import { MdRedo, MdUndo } from "react-icons/md";

import RigthSideBar from "../Style/Editor/RightSideBar";
import ThemeServices from "../../../lib/services/theme";
import { ConvertSelectors } from "./Convertable";

import { Preview } from './Preview';
import Add from './Add';

import {
  loadAnimationStyles,
  getAllCssData, clearStore, deleteStyleData,
  deleteAllActions, updateStyleData,
  storeAction, getAction, searchSelector,
  deleteAction, UNDO_STORE_NAME, REDO_STORE_NAME, STYLE_STORE_NAME, storePage, getData, deleteData, saveStyle, updateData
} from '../../../lib/db';

const { Option } = Select;
const { confirm } = Modal;

// Reducer function to manage state transitions
const reducer = (state, action) => {
  switch (action.type) {
    case "start":
      // When an operation starts, set loading to true
      return { ...state, loading: true }

    case "load":
      // Load page data into state
      console.log(action)
      return { ...state, page: {...state.page, ...action.page} }
    case "edit":
      // Edit an animation, reset selector and error
      return {
        ...state,
        animation: action.animation,
        elementType: action.elementType,
        selector: null,
        error: '',
      }
    case "add":
      // Add a selector, reset error
      return {
        ...state,
        selector: action.selector,
        error: ''
      }
    case "delete":
      // Delete a selector, reset error
      return { ...state, selector: null, error: '' }
    case "deleteSelector":
      // Delete a selector, reset error and update selectorDeleted
      return { ...state, selector: null, error: '', selectorDeleted: action.selectorDeleted }
    case "deleteAnimation":
      // Delete an animation, reset selector, error and update animationDeleted
      return {
        ...state, animation: {}, selector: null,
        animationDeleted: action.animationDeleted,
        error: ''
      }
    case "clearError":
      // Clear any error messages
      return { ...state, error: '' }
    case "error":
      // Set an error message
      return { ...state, error: action.error }
    case "finish":
      // When an operation finishes, set loading to false
      return { ...state, loading: false }
    default:
      // If the action type is not recognized, return the current state
      return state;
  }
};

export default function Animations({ menuKeys, children, onBack }) {

  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [isChange, setIsChange] = useState(false);
  const [isStyleChange, setIsStyleChange] = useState(false);



  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    animationsName: "",
    elementType: "",
    selector: null,
    animation: {},
    page: {},
    done: false,
    error: ''
  });


  useEffect(() => {

    const fetchData = async () => {
      try {
        dispatch({ type: 'start' });

        await clearStore(UNDO_STORE_NAME);
        await clearStore(REDO_STORE_NAME);
        await loadAnimationStyles(dispatch, true);
        dispatch({ type: 'load', page: { done: true } });

      } finally {

        dispatch({ type: 'finish' });
      }

    }

    fetchData();

  }, [])



  const onLoad = async (_id) => {

    dispatch({ type: 'load', page: { _id } });
  }

  const saveIsChange = (callback) => {

    if (isChange) {
      confirm({
        title: 'Please remember to update first?',
        icon: <ExclamationCircleFilled />,
        okText: 'Update',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          if (state.selector)
            await onUpdateSelector(state.selector);

          callback();
        },
        onCancel() {

        },
      });
    } else
      callback();
  }

  // Function to handle editing of animations
  const onEdit = async (animation) => {
    if (!animation || !state.page?._id) {
      saveIsChange(() => {
        dispatch({ type: 'edit', animation: null, elementType: '' });
      });
      return;
    }
    saveIsChange(() => {
      dispatch({ type: 'edit', animation: animation, elementType: animation.name });
    });
  }

  // Function to handle changes before closing the window
  useEffect(() => {
    async function promptBeforeClose(event) {
      if (isChange) {
        event.preventDefault();
        const shouldSave = await window.autocode.checkUnsavedChanges(isChange);
        if (shouldSave === 0) {
          await onSubmit();
        }
        setTimeout(() => {
          window.close();
        }, 100);
      }
      setIsChange(false);
    }
    window.addEventListener('beforeunload', promptBeforeClose);
    return () => {
      window.removeEventListener('beforeunload', promptBeforeClose);
    };
  }, [isChange]);

  // Function to handle form submission
  const onSubmit = async () => {
    dispatch({ type: 'start' });
    try {
      if (isStyleChange)
        await onUpdateSelector(state.selector);
      const page = state.page;
      await saveStyle(page, 'animation');
      setIsChange(false)
      dispatch({
        type: 'clearError'
      })
    } catch (e) {
      dispatch({ type: 'error', error: e?.message || 'Something went wrong.' });
    } finally {
      dispatch({ type: 'finish' });
    }
  };

  // Function to get the stylesheet from the styleSheets
  const getStyleSheet = (styleSheets) => {
    let sheet = null;
    for (let i = 0; i < styleSheets.length; i++) {
      sheet = styleSheets[i];
      if (sheet.ownerNode.id === 'ac-stylesheet-global')
        break;
    }
    return sheet;
  };

  // Function to delete an animation
  const onDeleteAnimation = async (STORE = UNDO_STORE_NAME, redo) => {
    try {
      // Check if animation exists
      if (!state.animation)
        throw new Error('Animation not selected!')

      const animation = state.animation;

      // Get the iframe and its document
      const iframe = document.querySelector("#ac-editor-iframe-doc");
      let iframeDocument = iframe.contentDocument;

      // Get the stylesheet and its rules
      const styleSheets = iframeDocument.styleSheets;
      const sheet = getStyleSheet(styleSheets);

      if (!sheet)
        throw new Error('Style Sheet not found!')


      const cssRules = sheet.cssRules || sheet.rules;

      // Filter out the rules that are not keyframes
      const rules = Array.from(cssRules).filter(rule => rule.type === 7);

      // Find the index of the rule with the same name as the animation
      const ruleIndex = rules.findIndex(item => item.name === animation.name);

      // If the rule exists, delete it
      if (ruleIndex >= 0) {
        sheet.deleteRule(ruleIndex);
      }

      // Delete the animation data
      await deleteData(state.page._id, animation._uid, STYLE_STORE_NAME);

      // Dispatch the deleteAnimation action
      dispatch({
        type: 'deleteAnimation',
        animationDeleted: animation._uid
      })

      // Set isChange to true to indicate that changes have been made
      setIsChange(true)

    } catch (e) {
      // Dispatch the error action if an error occurs
      dispatch({ type: 'error', error: e?.message || 'Something went wrong.' });

    }
  };

  // Function to select a selector
  const onSelectSelector = async (newSelector, STORE = UNDO_STORE_NAME, redo) => {
    try {
      // Destructure the newSelector object
      let { selectorText, properties = {}, store = STYLE_STORE_NAME, _uid } = newSelector;

      // Check if selectorText and animation exist
      if (!selectorText || !state.animation) {
        throw new Error('Animation || Selector not found.');
      }

      const animation = state.animation;

      // Dispatch the start action
      dispatch({ type: 'start' });

      // Validate the selectorText
      if (!validatClass(selectorText))
        throw new Error('Please Enter Valid Selector.');

      // Get the iframe and its document
      const iframe = document.querySelector("#ac-editor-iframe-doc");
      let iframeDocument = iframe.contentDocument;

      // Get the stylesheets
      const styleSheets = iframeDocument.styleSheets;
      // Get the stylesheet and its rules
      const sheet = getStyleSheet(styleSheets);

      if (!sheet)
        throw new Error('Style Sheet not found!')


      // Get the css rules
      let cssRules = sheet.cssRules || sheet.rules;

      // Find the keyframes rule for the animation or create it if it doesn't exist
      let ruleIndex = -1;
      for (let i = 0; i < cssRules.length; i++) {
        const rule = cssRules[i];
        if (rule.type === 7 && rule.name === animation.name) {
          ruleIndex = i;
          break;
        }
      }

      let keyframesRule;
      if (ruleIndex < 0) {
        keyframesRule = `@keyframes ${animation.name} {}`;
        ruleIndex = sheet.insertRule(keyframesRule, cssRules.length);
      }

      // Get the keyframes rules
      const keyframesRules = cssRules[ruleIndex];

      // Find the rule for the current selector or create it if it doesn't exist
      let index = -1
      for (let i = 0; i < keyframesRules.cssRules.length; i++) {
        const selector = keyframesRules.cssRules[i].keyText;
        if (selector === selectorText) {
          index = i;
          break;
        }
      }

      let rule;
      if (index < 0) {
        const ruleText = `${selectorText} {}`;
        index = keyframesRules.appendRule(ruleText);
        rule = keyframesRules.cssRules[keyframesRules.cssRules.length - 1];

      } else
        rule = keyframesRules.cssRules[index];

      // Create the selector object
      const selector = {
        style: rule.style,
        properties,
        selectorText: selectorText,
        animationName: animation.name,
        store
      };

      // Dispatch the add action
      dispatch({ type: 'add', selector });

    } catch (e) {
      // Dispatch the error action if an error occurs
      dispatch({ type: 'error', error: e?.message || 'Something went wrong.' });
    } finally {
      // Dispatch the finish action
      dispatch({ type: 'finish' });
    }
  };

  // Function to delete a selector
  const onDeleteSelector = async (selector, STORE = UNDO_STORE_NAME, isRedo) => {
    try {
      // Check if selector and animation exist
      if (!selector || !state.animation)
        throw new Error('Animation || Selector not found!');

      const { store, selectorText } = selector;
      const animation = state.animation;

      // Get the iframe and its document
      const iframe = document.querySelector("#ac-editor-iframe-doc");
      const iframeDocument = iframe.contentDocument;

      // Get the stylesheet and its rules
      const styleSheets = iframeDocument.styleSheets;
      const sheet = getStyleSheet(styleSheets);

      if (!sheet)
        throw new Error('Style Sheet not found!')


      let cssRules = sheet.cssRules || sheet.rules;

      // Find the keyframes rule for the animation
      let ruleIndex = Array.from(cssRules).findIndex(rule => rule.type === 7 && rule.name === animation.name);

      if (ruleIndex < 0) {
        throw new Error('Animation not found!');
      }

      let keyframeToDeleteKey = '';

      const keyframeRule = cssRules[ruleIndex];
      for (let i = 0; i < keyframeRule.cssRules.length; i++) {
        const rule = keyframeRule.cssRules[i];
        if (rule.keyText === selectorText) {
          keyframeToDeleteKey = rule.keyText;
          break;
        }
      }

      // Delete the animation style
      delete animation.style[selectorText];

      await updateData(state.page._id, animation, STYLE_STORE_NAME)

      setIsChange(true)
      dispatch({ type: 'deleteSelector', selectorDeleted: selectorText });

      if (keyframeToDeleteKey) {
        keyframeRule.deleteRule(keyframeToDeleteKey);
      } else {
        throw new Error('Selector not found!');
      }

    } catch (e) {
      dispatch({ type: 'error', error: e?.message || 'Something went wrong.' });
    }
  };

  // Function to update a selector
  const onUpdateSelector = async (selector, STORE = UNDO_STORE_NAME, isRedo) => {
    try {
      // Check if selector and animation exist
      if (!selector || !state.animation) {
        throw new Error('Animation || Selector not found!');
      }
      dispatch({ type: 'start' });

      const animation = state.animation;

      // Get the iframe and its document
      const iframe = document.querySelector("#ac-editor-iframe-doc");
      const iframeDocument = iframe.contentDocument;

      // Get the stylesheet and its rules
      const styleSheets = iframeDocument.styleSheets;
      const sheet = getStyleSheet(styleSheets);

      if (!sheet)
        throw new Error('Style Sheet not found!')

      let cssRules = sheet.cssRules || sheet.rules;

      // Find the keyframes rule for the animation
      let ruleIndex = Array.from(cssRules).findIndex(rule => rule.type === 7 && rule.name === animation.name);

      if (ruleIndex < 0) {
        throw new Error('Animation not found!');
      }

      // Find the rule for the current selector
      let index = Array.from(cssRules[ruleIndex].cssRules).findIndex(sel => sel.keyText === selector.selectorText);

      if (index < 0)
        throw new Error('Rule not found!');

      let rule = cssRules[ruleIndex].cssRules[index];

      if (rule) {
        // Remove existing properties
        const styleDeclaration = rule.style;
        Array.from(styleDeclaration).forEach(property => {
          styleDeclaration.removeProperty(property);
        });

        if (!selector.properties)
          selector.properties = {};

        // Set new properties
        Object.keys(selector.properties).forEach(key => {
          const value = selector.properties[key]?.value;
          const priority = selector.properties[key]?.priority;
          rule.style.setProperty(key, value, priority);
        });
      }

      // Update the animation style
      animation.style = { ...animation.style, [selector.selectorText]: {} };

      onAddAction({
        action: 'updateStyle',
        selector: {
          properties: animation.style[selector.selectorText].properties,
          selectorText: selector.selectorText,
          store: selector.store
        },
      }, STORE, isRedo);

      animation.style[selector.selectorText].properties = selector.properties;
      animation.style[selector.selectorText].selectorText = selector.selectorText;

      await updateData(state.page._id, animation, selector.store);

      setIsStyleChange(false);
      setIsChange(true)

      dispatch({ type: 'updateStyle' });

    } catch (e) {
      dispatch({ type: 'error', error: e?.message || 'Something went wrong.' });
    } finally {
      dispatch({ type: 'finish' });
    }
  };


  // Function to add an action
  const onAddAction = async (action, STORE, isRedo) => {
    // If it's not a redo action, delete all actions in the redo store
    if (!isRedo) {
      await deleteAllActions(REDO_STORE_NAME);
    }
    // Store the new action
    await storeAction(action, STORE);
  };

  // Function to perform an action
  const performAction = async (STORE1, STORE2) => {
    // Get the action from the store
    const action = await getAction(STORE1);

    // If there's no action, return
    if (!action) return;

    // Perform the action based on its type
    switch (action.action) {
      case 'updateStyle':
        await onUpdateSelector(action.selector, STORE2, true);
        break;
      default:
        break;
    }

    // Delete the action from the store
    await deleteAction(STORE1);
  };

  // Function to undo an action
  const undo = async () => {
    await performAction(UNDO_STORE_NAME, REDO_STORE_NAME);
  };

  // Function to redo an action
  const redo = async () => {
    await performAction(REDO_STORE_NAME, UNDO_STORE_NAME);
  };

  // Function to validate a class
  const validatClass = (inputValue) => {
    const regex = /^(100|[1-9][0-9]?|0)%|from|to$/;
    const isValid = regex.test(inputValue);
    return isValid;
  };

  // Function to handle changes
  const onChange = async (name, value, priority, unit) => {
    setIsChange(true);
    setIsStyleChange(true)
  }

  // Function to render the left tools
  const LeftTools = () => (
    <span>Setting: &nbsp;&nbsp;
      <ColorPicker
        value={backgroundColor}
        allowClear={true}
        format={"hex"}
        onChangeComplete={(color, hex) => {
          const metaColor = color.metaColor;
          const rgbColor = `rgba(${metaColor.r}, ${metaColor.g}, ${metaColor.b}, ${metaColor.a})`;
          setBackgroundColor(rgbColor)
        }}
      />
    </span>
  )

  const [isConverted, setIsConverted] = useState(false);
  useEffect(() => {
    setIsChange(true);
  }, [isConverted])
  // Function to render the right tools

  // Function to get the iframe and its document
  const getIframeAndDocument = () => {
    const iframe = document.querySelector("#ac-editor-iframe-doc");
    let iframeDocument = iframe.contentDocument;
    return { iframe, iframeDocument };
  };

  // Function to render the right tools
  const RightTools = () => (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginRight: 20 }}>
        <Tooltip placement="bottom" title="Undo">
          <Button
            icon={<MdUndo size={20} />}
            onClick={(e) => { e.preventDefault(); undo(); }}
            disabled={!isChange} // Disable the button if there's no change
          >
          </Button>
        </Tooltip>

        <Tooltip placement="bottom" title="Redo">
          <Button
            icon={<MdRedo size={20} />}
            onClick={(e) => { e.preventDefault(); redo(); }}
            disabled={!isChange} // Disable the button if there's no change
          >
          </Button>
        </Tooltip>

        {state.animation && <ConvertSelectors state={state}
          setIsConverted={setIsConverted}
          getIframeAndDocument={getIframeAndDocument}
          animation={state.animation} />}

      </div>
    </>
  )

  const [delay, setDelay] = useState(1);
  const [duration, setDuration] = useState(4);
  const [timeFun, setTimeFun] = useState('ease');
  const [direction, setDirection] = useState('normal');
  const [fillMode, setFillMode] = useState('none');
  const [playState, setPlayState] = useState('running');
  const [iterationCount, setIterationCount] = useState('infinite')
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);
  const [perspective, setPerspective] = useState(0);

  const [borderRadius, setBorderRadius] = useState(100);
  const [backgroundItemColor, setBackgroundItemColor] = useState('#000');

  const inputs = `${duration + "s"} ${timeFun} ${delay + "s"} ${iterationCount} ${direction} ${fillMode} ${playState}  ${state.animation?.name}`;
  const style = {
    animation: inputs,
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: `${borderRadius}px`,
    backgroundColor: backgroundItemColor,
    perspective
  }


  const handleBackClick = async (e) => {
    e.preventDefault();
    if (isChange) {
      const shouldSave = await window.autocode.checkUnsavedChanges(isChange);

      if (shouldSave === 0) {
        await onSubmit();
      }

      if (shouldSave === 2) {
        return;
      }
    }

    // Navigate back
    onBack();
  };

 

  return <div>

    <div style={{ textAlign: 'center', padding: 10 }}>
      <img src="./images/logo/nav-logo.png" width="50" alt="autocode-logo" />
    </div>

    <Card loading={state.loading && !document}
      headStyle={{ padding: 10 }}
      bodyStyle={{ padding: 0 }}
      extra={<><Button type="dashed" style={{ marginRight: 10 }}
        loading={state.loading}
        onClick={handleBackClick}>Back</Button> <Button type="primary" onClick={onSubmit} loading={state.loading} disabled={!isChange}>Save</Button></>}
      title={<><div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <LeftTools /> <div style={{ width: 400 }}>
          <Add onDelete={onDeleteAnimation}
            animation={state.animation}
            state={state}
            onChange={() => setIsChange(true)}

            onEdit={onEdit} />
        </div><RightTools /></div></>}>

      {state.error && <Alert message={state.error}
        style={{ margin: '10px 0' }} type="error" showIcon closable />}
      <div style={{ display: 'flex', minHeight: '80vh', position: 'relative' }}>


        <Spin tip="Loading" size="small" spinning={state.loading}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
        </Spin>

        <div style={{ width: 280 }}>
          {state.animation ? (
            <div>
              <Card>
                <div style={{ marginBottom: 16 }}>
                  <label>Width: </label>&nbsp; &nbsp;
                  <InputNumber
                    value={width}
                    onChange={(value) => setWidth(value)}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Height: </label> &nbsp; &nbsp;
                  <InputNumber
                    value={height}
                    onChange={(value) => setHeight(value)}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Border Radius: </label> &nbsp; &nbsp;
                  <InputNumber
                    value={borderRadius}
                    onChange={(value) => setBorderRadius(value)}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Background:</label> &nbsp; &nbsp;


                  <ColorPicker value={backgroundItemColor}
                    allowClear={true}
                    format={"hex"}
                    onChangeComplete={(color, hex) => {
                      const metaColor = color.metaColor;
                      const rgbColor = `rgba(${metaColor.r}, ${metaColor.g}, ${metaColor.b}, ${metaColor.a})`;
                      setBackgroundItemColor(rgbColor)
                    }} />

                </div>

                <div style={{ marginBottom: 16 }}>
                  <label>Perspective:</label> &nbsp; &nbsp;
                  <InputNumber
                    value={perspective}
                    onChange={(value) => setPerspective(value)}
                  />

                </div>

                <Divider orientation="left" orientationMargin="0">Animation</Divider>
                <div style={{ marginBottom: 16 }}>
                  <label>Name: </label> &nbsp; &nbsp;
                  <strong>{state.animation.name}</strong>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Duration: </label> &nbsp; &nbsp;
                  <InputNumber
                    value={duration}
                    onChange={(value) => setDuration(value)}
                  />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Timing Function: </label> &nbsp; &nbsp;
                  <Select
                    style={{ width: 120 }}
                    value={timeFun}
                    onChange={(value) => setTimeFun(value)}
                  >
                    <Option value="linear">linear</Option>
                    <Option value="ease">ease</Option>
                    <Option value="ease-in">ease-in</Option>
                    <Option value="ease-in-out">ease-in-out</Option>
                    <Option value="ease-out">ease-out</Option>
                    <Option value="cubic-bezier(0.1, -0.6, 0.2, 0)">
                      cubic-bezier(0.1, -0.6, 0.2, 0)
                    </Option>
                    <Option value="cubic-bezier(0, 1.1, 0.8, 4)">
                      cubic-bezier(0, 1.1, 0.8, 4)
                    </Option>
                    <Option value="steps(3, start)">steps(3, start)</Option>
                    <Option value="steps(4)">steps(4)</Option>
                  </Select>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Delay: </label>&nbsp; &nbsp;
                  <InputNumber value={delay} onChange={(value) => setDelay(value)} />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Direction: </label>&nbsp; &nbsp;
                  <Select
                    style={{ width: 120 }}
                    value={direction}
                    onChange={(value) => setDirection(value)}
                  >
                    <Option value="normal">normal</Option>
                    <Option value="reverse">reverse</Option>
                    <Option value="alternate">alternate</Option>
                    <Option value="alternate-reverse">alternate-reverse</Option>
                  </Select>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Fill Mode: </label>&nbsp; &nbsp;
                  <Select
                    style={{ width: 120 }}
                    value={fillMode}
                    onChange={(value) => setFillMode(value)}
                  >
                    <Option value="none">none</Option>
                    <Option value="forwards">forwards</Option>
                    <Option value="backwards">backwards</Option>
                    <Option value="both">both</Option>
                  </Select>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Iteration Count: </label>&nbsp; &nbsp;
                  <InputNumber max={100} min={1} value={iterationCount} onChange={(value) => setIterationCount(value)} />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Infinite: </label>&nbsp; &nbsp;
                  <Checkbox value={iterationCount === 'infinite'} onChange={(e) => setIterationCount(iterationCount === 'infinite' ? '1' : 'infinite')} />
                </div>
                <div style={{ marginBottom: 16 }}>
                  <label>Play State: </label>&nbsp; &nbsp;
                  <Radio.Group
                    value={playState}
                    onChange={(e) => setPlayState(e.target.value)}
                  >
                    <Radio value="running">Running</Radio>
                    <Radio value="paused">Paused</Radio>
                  </Radio.Group>
                </div>

              </Card>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div style={{ flex: 1, overflow: 'auto' }}>

          <AnimationsIFrame onLoad={onLoad}>
            <div style={{ backgroundColor, width: '100%', height: '100%' }}>
              <Preview style={style} />
            </div>
          </AnimationsIFrame>
        </div>


        <RigthSideBar
          state={state}
          onSelect={onSelectSelector}
          onDelete={onDeleteSelector}
          onSubmit={onUpdateSelector}
          onChange={onChange}
          isConverted={isConverted}
          animation={state.animation}

          menuKeys={menuKeys}
        >
          <div style={{ textAlign: 'right', marginTop: 10, padding: 10 }}>
            <Button type="primary" onClick={(e) => {
              e.preventDefault();
              onUpdateSelector(state.selector);
            }

            } loading={state.loading} disabled={!isStyleChange}> Update </Button>
          </div>
        </RigthSideBar>

      </div>





    </Card>
  </div>
}

