
import Icons from './Icons';

export default function IconsComponent({ editor }) {

    return <div style={{ padding: '10px 10px 0 10px' }}>
        <Icons editor={editor} />
    </div>

};

