import { Form, Input, Button, message, Cascader, Select } from "antd";
import { useState, useReducer, useEffect, useCallback } from "react";
import { debounce } from '../../../../../../../lib/utils';
import DocumentServices from "../../../../../../../lib/services/documents";

// Initial state for the reducer
const initial = {
    documents: [],
    loading: false
}

const { Option } = Select;

// Reducer function to manage state
const reducer = (state, action) => {
    switch (action.type) {
        case "start":
            return { ...state, loading: true }
        case "documents":
            return { ...state, documents: action.data }
        case "finish":
            return { ...state, loading: false }
        default:
            return state;
    }
};

export default function AddFilter({
    current,
    setCurrent,
    onAdd,
    componentStates,
    documentStates,
    relationships,
    withRelationship = false }) {

    const [newFilter, setNewFilter] = useState(current);
    const [documentSearch, setDocumentSearch] = useState('');
    const [states, dispatch] = useReducer(reducer, initial);

    // Function to load documents
    const loadDocuments = useCallback(async () => {
        dispatch({ type: 'start' });
        try {
            const promises = relationships.map(relationship => DocumentServices.search(relationship, documentSearch));
            const results = await Promise.all(promises);
            const documents = results.map(result => result.documents);
            dispatch({ type: 'documents', data: documents });
        } catch (e) {
            message.error(e?.message || 'Something went wrong.');
        } finally {
            dispatch({ type: 'finish' });
        }
    }, [relationships, documentSearch]);

    // Effect to load documents when documentSearch changes
    useEffect(() => {
        if (documentSearch && Array.isArray(relationships)) {
            const delayedLoadDocuments = debounce(loadDocuments, 500);
            delayedLoadDocuments();
        }
    }, [documentSearch, loadDocuments, relationships]);

    // Function to handle filter key change
    const onChangeFilterKey = (keys) => {
        if (!keys || !keys[0]) {
            setNewFilter({})
        } else {
            const [first, ...rest] = keys;
            setNewFilter({ ...newFilter, filterKey: rest, type: first })
        }
    }

    // Function to handle trigger key change
    const onChangeTriggerKey = (keys) => {
        if (newFilter) {
            if (!keys || !keys[0]) {
                setNewFilter({ ...newFilter, triggerType: undefined, triggerKey: [] });
            } else {
                const [first, ...rest] = keys;
                setNewFilter({ ...newFilter, triggerType: first, triggerKey: rest })
            }
        }
    }

    // Function to handle filter default relationships change
    const onChangeFilterDefaultRelationships = (e, options) => {
        if (newFilter && options) {
            newFilter.defaultRelationshipsFilter = options.map(val => { return { _id: val._id, name: val.label } });
            setNewFilter({ ...newFilter });
        }
    }

    // Function to handle filter name change
    const onChangeFilterName = (e) => {
        if (newFilter) {
            setNewFilter({ ...newFilter, name: e.target.value })
        }
    }

    // Function to handle filter default value change
    const onChangeFilterDefault = (e) => {
        if (newFilter) {
            setNewFilter({ ...newFilter, defaultValue: e.target.value });
        }
    }

    // Function to handle form submission
    const onSubmit = async (e) => {
        e.preventDefault();
        if (newFilter.name && newFilter.type) {
            await onAdd(newFilter);
        }
    }

    // Function to render dropdown
    const dropdownRender = (menu) => {
        return (
            <>
                {states.loading && <div>Loading...</div>}
                {menu}
            </>
        );
    }

    return (
        <>
            <Form.Item label="Filter Name" >
                <Input placeholder="Name"
                    onChange={onChangeFilterName}
                    value={newFilter?.name}
                    disabled={newFilter?.editMode}
                    required />
            </Form.Item>
            <Form.Item label="Filter Key" >
                <Cascader options={documentStates}
                    value={newFilter ? [newFilter.type, ...(Array.isArray(newFilter.filterKey) ? newFilter.filterKey : [])] : [undefined]}
                    onChange={onChangeFilterKey} style={{ width: '100%' }}>
                </Cascader>
            </Form.Item>
            {newFilter?.type && <>
                <Form.Item label="Default Value" >
                    {newFilter.type === '__relationship__' && withRelationship ? <Select
                        style={{ width: '100%' }}
                        placeholder="Select Relationship"
                        showSearch
                        clearIcon
                        mode="multiple"
                        onChange={onChangeFilterDefaultRelationships}
                        onSearch={(v) => {
                            if (!states.loading) {
                                setDocumentSearch(v)
                            }
                        }}
                        dropdownRender={dropdownRender}
                        optionFilterProp="label"
                        value={Array.isArray(newFilter.defaultRelationshipsFilter) ? newFilter.defaultRelationshipsFilter.map(val => val._id) : []}
                    >
                        {Array.isArray(newFilter.defaultRelationshipsFilter) &&
                            newFilter.defaultRelationshipsFilter.map((relationship, index) => <Option
                                key={index} disabled
                                value={relationship._id}
                                label={relationship.name}>{relationship.name}</Option>)}
                        <Option value="" label="">All</Option>
                        {states.documents.map(document => <Option key={document._id} value={document._id} label={document.name}>{document.name}</Option>)}
                    </Select> : <Input placeholder="Value"
                        onChange={onChangeFilterDefault}
                        value={newFilter.defaultValue}
                        required />}
                </Form.Item>
                <Form.Item label="Trigger Key" >
                    <Cascader options={componentStates}
                        value={[newFilter.triggerType, ...(Array.isArray(newFilter.triggerKey) ? newFilter.triggerKey : [])]}
                        onChange={onChangeTriggerKey} style={{ width: '100%' }}>
                    </Cascader>
                </Form.Item>
            </>}
            <div style={{ textAlign: 'right' }}>
                <Button type="default" onClick={() => { setCurrent(null) }}>Cancel</Button>   &nbsp;
                <Button type="primary" onClick={onSubmit} disabled={!newFilter.name || !newFilter.type}> Add </Button>
            </div>
        </>
    );
};
