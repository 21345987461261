import React, { useState, useEffect, useRef, useCallback } from 'react';



const Observable = (props) => {
    const { children, states, node, updateStates } = props;
    const ref = useRef();

    const [vValue, setVisibleValue] = useState('')
    const [sTValue, setScrollTopValue] = useState('')
    const [sLValue, setScrollLeftValue] = useState('')

    const [vTValue, setVisibleTopValue] = useState(0)

    // Function to get default value from states
    const getDefaultValue = (states, key, defaultValue) => states[key] ? states[key].defaultValue : defaultValue;

    const tagName = getDefaultValue(states, 'tagName', 'div');

    const onScroll = getDefaultValue(states, 'onScroll', false);
    const scrollTop = getDefaultValue(states, 'scrollTop', 50);
    const scrollLeft = getDefaultValue(states, 'scrollLeft', 50);
    const scrollTopValue = getDefaultValue(states, 'scrollTopValue', "");
    const scrollLeftValue = getDefaultValue(states, 'scrollLeftValue', "");


    const onVisible = getDefaultValue(states, 'onVisible', false);
    const calculateVisibility = getDefaultValue(states, 'calculateVisibility', false);

    const oneTime = getDefaultValue(states, 'oneTime', false);
    const threshold = getDefaultValue(states, 'threshold', 10 / 100);
    const visibleValue = getDefaultValue(states, 'visibleValue', "");
    const inVisibleValue = getDefaultValue(states, 'inVisibleValue', "");


    // Function to handle scroll event
    const handleScroll = () => {
        if (onScroll) {
            const iframe = document.querySelector("#ac-editor-iframe-doc");
            if (iframe) {
                const iframeWindow = iframe.contentWindow;
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                const top = iframeWindow.scrollY || iframeDocument.documentElement.scrollTop;
                const left = iframeWindow.scrollX || iframeDocument.documentElement.scrollLeft;
                if (top > scrollTop)
                    setScrollTopValue(scrollTopValue)
                else
                    setScrollTopValue('')
                if (left > scrollLeft)
                    setScrollLeftValue(scrollLeftValue)
                else
                    setScrollLeftValue('')
            }
        }
    };

    useEffect(() => {

        if (onScroll) {

            const iframe = document.querySelector("#ac-editor-iframe-doc");

            if (iframe) {
                const iframeWindow = iframe.contentWindow;
                iframeWindow.addEventListener('scroll', handleScroll);
            }
        }

        return () => {
            if (onScroll) {
                const iframe = document.querySelector("#ac-editor-iframe-doc");
                if (iframe) {
                    const iframeWindow = iframe.contentWindow;
                    iframeWindow.removeEventListener('scroll', handleScroll);
                }
            }
        };
    }, [onScroll, scrollLeft, scrollTop, scrollTopValue, scrollLeftValue]);

    let observer;

    // Function to handle visibility
    const handleVisibility = () => {
        if (ref.current && onVisible) {
            observer = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting && entry.intersectionRatio >= threshold) {
                        setVisibleValue(visibleValue);
                        if (oneTime) {
                            observer.disconnect();
                            return;
                        }
                    } else {
                        setVisibleValue(inVisibleValue);
                    }
                });
            }, { threshold: threshold });
            observer.observe(ref.current);
        }
        return () => {
            if (onVisible) {
                observer.disconnect();
            }
        };
    };
    useEffect(() => handleVisibility, [
        visibleValue,
        inVisibleValue,
        onVisible,
        threshold,
        ref.current,
        oneTime]);


    const calculateElementTopVisibility = useCallback(() => {
        if (ref.current) {
            const iframe = document.querySelector("#ac-editor-iframe-doc");
            if (iframe) {
                const iframeWindow = iframe.contentWindow;
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                const scrollTop = iframeWindow.scrollY || iframeDocument.documentElement.scrollTop;

                const viewportHeight = iframeWindow.innerHeight;
                const rect = ref.current.getBoundingClientRect();
                // Vertical visibility
                const elementTop = rect.top + scrollTop;
                const elementBottom = rect.bottom + scrollTop;

                const visibleTop = Math.max(elementTop, scrollTop);
                const visibleBottom = Math.min(elementBottom, scrollTop + viewportHeight);

                let visibleHeight = visibleBottom - visibleTop;
                visibleHeight = Math.max(visibleHeight, 0);

                const elementHeight = rect.height;
                const visiblePercentageVertical = (visibleHeight / elementHeight) * 100;


                // Assuming onScrollDiffer is a callback prop to parent component
                setVisibleTopValue(visiblePercentageVertical);
            }
        }
    }, []);



    useEffect(() => {

        if (calculateVisibility) {
            const iframe = document.querySelector("#ac-editor-iframe-doc");
            if (iframe) {
                const iframeWindow = iframe.contentWindow;
                iframeWindow.addEventListener('scroll', calculateElementTopVisibility);
            }
        }
        return () => {
            if (calculateVisibility) {
                const iframe = document.querySelector("#ac-editor-iframe-doc");
                if (iframe) {
                    const iframeWindow = iframe.contentWindow;
                    iframeWindow.removeEventListener('scroll', calculateElementTopVisibility);
                }
            }
        };
    }, [calculateVisibility, ref.current]);



    useEffect(() => {
        updateStates('updatedVisibleValue', vValue);
    }, [vValue]);

    useEffect(() => {
        updateStates('upatedTopValue', sTValue);
    }, [sTValue]);

    useEffect(() => {
        updateStates('upatedLeftValue', sLValue);
    }, [sLValue]);

    const deAttach = () => {
        if (oneTime) {
            const iframe = document.querySelector("#ac-editor-iframe-doc");
            if (iframe) {
                const iframeWindow = iframe.contentWindow;
                iframeWindow.removeEventListener('scroll', calculateElementTopVisibility);
            }
        }
    }

    useEffect(() => {

        if (calculateVisibility && !oneTime) {
            const iframe = document.querySelector("#ac-editor-iframe-doc");
            if (iframe) {
                const iframeWindow = iframe.contentWindow;
                iframeWindow.addEventListener('scroll', calculateElementTopVisibility);
            }
        }

    }, [oneTime])

    useEffect(() => {
        if (vTValue) {
            updateStates('visibleY', vTValue);
            if (vTValue >= 100)
                deAttach();
        }

    }, [vTValue, oneTime]);

    return React.createElement(tagName, { ref }, children)
};

export default Observable;